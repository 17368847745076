div.noticeBar {
    background: $alert;
    color: $light;
    display: block;
    width: 100%;
    padding: .5rem 0;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index:101;

    &:before {
        font-family: $icon-font;
        @extend %icon-attention;
        margin-right: 1rem;
        font-size: rem(24px);
        font-weight: normal;
    }

    a {
        color:$light;
    }

}

noscript div:first-child {
    display: none;
}

.breadcrumb li {
    list-style: none;
    float: left;

    &:before {
        content: ">";
        padding: 0 1rem;
    }

    &:first-child:before {
        content: "";
        padding: 0;
    }

}

/**
 * Debug mode
 */
%debug {
    height: 100%;
    overflow-y: scroll;
    background: $light;
    padding: 1%;
}

#log_container {
    height: 100%;
    width: 100%;
    position: fixed;
    top: -100%;
    left: 0;
    color: $dark;
    background: darken($light, 5%);
    padding: 2%;
    transition: all 500ms ease-in-out;
    box-shadow: inset 0 0 rem(1px) rem(1px) rgba($alert, .5);
    z-index: 99999999;

    pre {
        @extend %debug;
    }

    .checkupXYZ {
        @extend %debug;
    }

    &:target {
        top: 0;
    }

    &.show {
        top: 0;
    }

    ul {
        list-style: inside square;
        ul {
            margin-left: 2rem;
        }

        h3 {
            display: inline-block;
        }

        li {
            padding: 0.5rem 0;
            border-bottom: rem(1px) solid darken($light, 5%);
            &:last-child {
                border: none;
            }
        }
    }

    h1 {
        text-align: center;
        padding-top: 2rem;
    }

    h2 {
        padding-bottom: 0.5rem;
        border-bottom: rem(1px) solid $medium;
        text-align: center;
        font-weight: normal;
        margin-top: 4rem;
    }

    h3 {
        font-weight: normal;
    }

    .checkup_pages {
        li {
            padding: 1rem;
        }

        .sub {
            margin-left: 2rem;
        }
    }

    > p {
        margin-bottom: 4rem;
    }

}

.checkup_pages > li:nth-child(odd) {
    background: #F4F4F4;
}


.checkupXYZ {
    width: 960px;
    margin: 0 auto;
    resize: horizontal;
}

.informatizer {
    background: #8DD45C;
    padding: 1%;
    position: fixed;
    top: 2%;
    right: 2%;
    display: block;
    color: $light;
    transition: all 500ms ease-in-out;
    text-decoration: none;

    &:before {
        font-family: $icon-font;
        @extend %icon-info;
        margin-right: 1rem;
        font-size: em(24px);
        line-height: 1rem;
    }

    a {
        color: $light;
    }

}

/**
 * Accordion
 */
.accHandler {
    cursor: pointer;
	padding: rem(10px);
	border: 1px solid $secondary;
	font-size: rem(18px);
    position: relative;
    padding-right: rem($base-gap);

    &:after {
        @extend %icon-plus;
        display: inline-block;
        font-family: $icon-font;
        font-size: em(9px);
        transition: all 300ms ease-in-out;
        position: absolute;
        right: rem($base-gap / 2);
        top: 50%;
        transform: translateY(-50%);
    }

    &.active:after {
        @extend %icon-minus;
    }

	&.active, &:hover {
        background: $secondary;
		color: #fff;
    }

}

/**
 * Back to top
 */
.backToTop {
    background: $secondary;
    color: $dark;
    text-decoration: none;
    position: fixed;
    bottom: 2%;
    right: 2%;
    @include hide-text();
    height:rem(45px);
    opacity: .9;
    width:rem(45px);
    z-index: 101;

    &:before {
        @extend .icon;
        @extend %icon-up;
        display: inline-block;
        position: absolute;
        top:50%;
        left:50%;
        transform:translate(-50%, -50%);
        text-indent:0;
    }

    &:hover, &:active, &:focus {
        background:$primary;
        color:$light;
    }
}
