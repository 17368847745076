// Form styles
form.default {
    overflow: hidden;
    width: 100%;

    ::-webkit-input-placeholder, :-ms-input-placeholder, ::-moz-placeholder {
        color: $medium;
    }

    fieldset {
        border: none;
        margin: 0 0 2rem;

        + fieldset {
            margin-top: 2rem;
        }

        > span {
            float: left;
            width: 6rem;
            margin-top: rem(5px);
        }
    }

    label {
        display: block;
        padding: .8rem 0;

        &[for="Datenschutz"] {
            width: calc(100% - #{rem(30px)});
        }

        &.radio {
            clear: none;
            display: inline-block;
            width: 30%;
        }

        &.checkbox {
            display: inline-block;
            width:auto;
        }

        small {
            color: $alert;
            display: block;
            line-height: 1rem;
        }

        &.error {
            width: 100%;
            margin-bottom: 1rem;
            background: $alert;
            background: $alert;
            color: $light;
            padding: rem(5px);
            text-align: center;
            position: relative;
            animation: error 1s;
            -webkit-animation: error 1s;

            &:before {
                border-left: rem(7px) solid transparent;
                border-right: rem(7px) solid transparent;
                border-bottom: rem(10px) solid $alert;
                border-top: 0;
                height: 0;
                right: rem(10px);
                position: absolute;
                top: rem(-10px);
                width: 0;
                content: "";
            }
        }

        @include breakpoint(small) {
            clear: both;
            float: left;
            padding-right:1rem;
            width: 33%;
        }
    }


    legend {
        background: none;
		border: 1px solid $secondary;
        color: $primary;
        font-size: rem(18px);
		text-transform: uppercase;
		font-family: $display-font;
        margin: 0 0 1rem 0;
        padding: .6rem;
        width: 100%;

        + p {
            background: darken($light, 5%);
            margin: -1.3rem 0 1rem 0;
            padding: 1.5% .6rem;
        }
    }

    [type="text"] {
        @extend %form-field;
    }

    [type="tel"] {
        @extend %form-field;
    }

    [type="email"] {
        @extend %form-field;
    }

    [type="file"] {
        @extend %form-field;
    }

    textarea {
        @extend %form-field;
    }

    select {
        @extend %form-field;
    }

    [type="checkbox"] {
        float: left;
        margin: 1rem .3rem .8rem;
    }

    button {
        background: $primary;
        border: none;
        cursor: pointer;
        color: $light;
        font-family: $main-font;
        font-size: 1rem;
        margin-bottom:1rem;
        padding: .8rem;
        width: 100%;

        &:hover, &:active {
            background:$secondary;
        }

        @include breakpoint(small) {
            float: right;
            width:67%;
        }
    }

    .errorContainer {
        position: relative;
    }

}

/*=VALIDATION */

@keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@-webkit-keyframes error {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.specialfield {
    display: none;
}

#newsletter-info {
    border: rem(1px) solid $alert;
    padding: 1rem;
}

img[name=vimg] + input[name=imgverify] {
    float:right;
}
