/*=NAVIGATION MAIN */

nav {

    @include breakpoint(large) {
        
        border-top: rem(5px) solid $secondary;

    }

    @include breakpoint(giant) {
        
        border-top: none;

    }
   
}
.navi-main {
    
     @include breakpoint(large) {
        
        max-width: rem(1039px);
        margin: 0 auto;
        position: relative;
     }


     @include breakpoint(giant) {
        
        border-top: rem(5px) solid $secondary;
        max-width: rem(1168px);
    }
    

    li {


        @include breakpoint(large) {
                
            float: left;
            display: inline;
        }



        &:hover {
            a {

            }
        }

        &.active {
            a {

            }
        }

        a {

            font-family: $display-font;
            font-size: rem(15px);
            text-transform: uppercase;
            display: block;
            width: 100%;
            padding: rem(5px) rem(5px) rem(5px) rem(13px);
            border-bottom: 1px solid $secondary;

            &.active { color: $secondary;}

            @include breakpoint(large) {

                &.startseite,
                &.kontakt,
                &.impressum,
                &.datenschutzerklaerung,
                &.anfahrt {

                    display: none;
                }

                font-size: rem(13px);
                border-bottom: none;
                border-right: 1px solid $secondary;
                line-height: 30%;
                margin-top: rem(30px);
                margin-bottom: rem(60px);
                padding-right: rem(13px);

                &.archiv {
                    border: none;
                    padding-right: 0;

                }

            }

            @include breakpoint(large-more) {

                font-size: rem(14px);

            }

            @include breakpoint(giant) {

                font-size: rem(16px);
                padding: rem(5px) rem(15px) rem(5px) rem(15px);

                &.die-kanzlei {
                   padding-left: 0;

                }
            }

        }
    }

    .sub {

        @include breakpoint(large) {

            position: absolute;
            background: #fff;
            z-index: 100;
            top: rem(50px);
            border: 1px solid $secondary;
            padding: rem(15px) rem(15px) 0 rem(15px);
            display: none;
            border-top: none;

        }

        li {
            
            @include breakpoint(large) {
                
                float: none;
                display: block;
                margin-bottom: rem(10px);

            }


        }

        a {
            
            color: $primary!important;
            &:before {

                content: "» ";
                font-size: rem(14px);
                color: $alert;
            }

            &:hover, &.active {

                    color: $secondary!important;
            }

            @include breakpoint(large) {
                
                padding: 0;
                margin:0;
                line-height: 120%;
                border-right: none;

            }

        }
    }
}


.navi-main li.active a { color: $secondary;}
.navi-main li:hover ul { display: block!important;}
.navi-main li.hovering ul { display: block!important;}


// Off-canvas navigation
.page-navi {
    background: $light;
    min-height: 100%;
    transform: translateX(-100%);
    @extend %animated-transform;
    left: 0;
    position: absolute;
    top: 0;
    width: 80%;
    z-index: 100;

    @include breakpoint(large) {
        background: $light;
        transform: translateX(0);
        transition: none;
        min-height: inherit;
        position: relative;
        width: 100%;
    }
}

.toggle-navi, .close-navi {
    color: $dark;
    font-size: rem(32px);
    text-decoration: none;

    &:before {
        font-family: $icon-font;
        @extend %icon-menu;
        transition: content 300ms;
        line-height: 2.6em;
    }

    &:hover {
        &:before {
            color: $primary;
        }
    }

    @include breakpoint(large) {
        display: none;
    }
    
    span {

        font-family: $display-font;
        font-size: 25px;
        text-transform: uppercase;
        margin-top: -10px;

    }
}

.close-navi {
    display: none;
}

.navi-buttons {
    float: left;
    width: 100%;
    padding-left: 15px;
}

#navi-toggled:target {
    .page-navi {
        transform: translateX(0);
    }

    .page-wrap {
        transform: translateX(80%);
        position: fixed;
    }

    .close-navi {
        display: block;

        &:before {
            @extend %icon-cancel;
        }
    }

    .toggle-navi {
        display: none;
    }

    @include breakpoint(large) {
        .close-navi {
            display: none;
        }

        .page-wrap {
            transform: translateX(0);
            position: relative;
        }
    }
}
