// Styled lists
dl.floatList {
    dt, dd {
        display: inline-block;
        vertical-align: top;
    }

    dt {
        padding-right: .5rem;
        min-width: 40%;
    }

    dd {
        width: 60%;
    }
}

.unstyled {
    margin-bottom: 0;

    li {
        padding: 0;

        &:before {
            display: none;
        }
    }

    ul {
        margin: 0;
    }
}

.page-wrap {
    transform: none;
    @extend %animated-transform;

    body.nojs &, body.oldBrowser & {
        padding-bottom: rem(117px);
        @include breakpoint(tiny) { padding-bottom: rem(90px); }
        @include breakpoint(small) { padding-bottom: rem(67px); }
        @include breakpoint(giant) { padding-bottom: rem(42px); }
    }
}


// Header
.header {

    position: relative;
    width: 100%;

    @include breakpoint(giant) {

        width: rem(1200px);
        margin: 0 auto;

    }

    @include breakpoint(small) {

        hr {
            display: none;
        }
    }


    .header-top {

        @include breakpoint(small) {

            float: none;
            width: rem(600px);
            margin: 0 auto;

        }

        @include breakpoint(large) {

            float: left;
            width: 66.6666666667%;
            margin: 0;
            margin-bottom: rem(60px);

        }

        @include breakpoint(giant) {

            width: 50%;

        }


    }

    #slideshow {

        margin-bottom: rem(15px);
        height: rem(211px);
        overflow-y: hidden;

        @include breakpoint(tiny) {

            margin-top: 15px;

        }

        @include breakpoint(large) {

             margin-top: 0;
        }

        .slide-1 {

            background: url(../images/slideshow/kassel.jpg) center center no-repeat;
            background-size: cover;
            height: rem(211px);
            width: 100%;
			overflow-y: hidden;
        }

		.slide-2 {

            background: url(../images/slideshow/herkules.jpg) center center no-repeat;
            background-size: cover;
            height: rem(211px);
            width: 100%;
			overflow-y: hidden;
        }

        .slide-3 {

            background: url(../images/slideshow/steuerberatung.jpg) center center no-repeat;
            background-size: cover;
            height: rem(211px);
            width: 100%;
            overflow-y: hidden;
        }

        .slide-4 {

            background: url(../images/slideshow/kassel-2.jpg) center center no-repeat;
            background-size: cover;
            height: rem(211px);
            width: 100%;
            overflow-y: hidden;
        }

        .slide-5 {

            background: url(../images/slideshow/taschenrechner.jpg) center center no-repeat;
            background-size: cover;
            height: rem(211px);
            width: 100%;
            overflow-y: hidden;
        }
    }

    .adresse {



            width: rem(290px);
            margin: 0 auto;
            float: none;
            padding-left: 0;


        @include breakpoint(small) {

            float: left;
            width: 49%;
            padding-left: 1.2857142857rem;
            padding-right: 0;
            border-right: 1px solid $medium;
        }

        @include breakpoint(large) {

            width: 44%;
        }

        @include breakpoint(giant) {

                 width: 50%;

        }


        p.dl {

            i {
                float: left;
                width: rem(30px);
                padding-top: rem(3px);
            }
        }

        a {

            color: $secondary;

            &:hover {

                color: $medium;
            }
        }
    }

    .opentimes {

        width: rem(290px);
        margin: 0 auto;
        float: none;
        padding: 0;

        @include breakpoint(small) {

            float: left;
            width: 50%;
            padding-left: 1.6rem;
        }

        @include breakpoint(giant) {

                 width: 50%;

        }


        p.dl {

            span {

                 float: left;
                 width: rem(150px);
            }
        }
    }

    @include breakpoint(large-more) {

        .grey {


                background: $grey;
                padding: rem(20px) rem(15px) rem(15px) rem(15px);

        }

        .teaser {
            margin-bottom: rem(40px);
            position: relative;
            min-height: rem(250px);

            p {

                hyphens: auto!important;
                font-size: 12px;
                line-height: rem(21px);

            }

            .top {

                margin-top: rem(20px);
            }




        a.weiter {

            color: $secondary!important;
            text-transform: uppercase;
            font-family: $display-font;
            position: absolute;
            bottom: rem(15px);
            left: rem(20px);


            &:hover {

                color: $medium!important;
            }
        }

    }


    }

     @include breakpoint(giant) {


        .teaser {

            margin-bottom: rem(40px);

            p {

                hyphens: auto!important;
                font-size: rem(13px);

            }
        }

     }

     @include breakpoint(giant) {

            div.inner {

                max-width: rem(1184px);
            }

            .sufffix {
                padding-right: 0;
            }
     }


    @include breakpoint(large-more) {

        .sufffix {

            padding-left: 0;
        }

    }


    #suche {

         .suchebutton {

            float: left;
            width: 20%;
            border: 1px solid $primary;

            body.iexplore & {
                padding: 0.5rem 0.4rem;
            }

            &:hover {

                border: 1px solid $secondary;

            }
         }

         input.suchfeld {

            width: 80%;
            float: left;
         }
    }
}

body.firefox  #suche .suchebutton {

            border: none;

            &:hover {

                border: none;

            }
         }

.branding {
    display: none;

    @include breakpoint(small) {

        display: block;
        margin-bottom: rem(45px);

    }

    @include breakpoint(large) {

        margin-bottom: rem(30px);
    }
}

.mobilebranding {

    display: block;
    width: rem(290px);
    margin: rem(20px) auto rem(20px) auto;

    @include breakpoint(small) {

    display: none;
    }

    img {

         width: rem(290px);


    }
}

.content {

    padding: rem(30px) rem(0) rem(30px) rem(0);

    @include breakpoint(giant) {

        padding: rem(30px) 0 rem(60px) 0;

    }

	img.default100 {

		width: 100%;
		height:auto;

		@include breakpoint(tiny) {

				width: auto;
				height:auto;
		}
	}
	img.floatLeft {

		float: left;
		margin: 0 rem(18px) rem(18px) 0;
	}


    div.spacer {

        @include breakpoint(giant) {

        clear: both;
        margin: rem(30px) 0 rem(80px) 0;

        }
    }

    p {

        margin-bottom: rem(27px);
    }


    img.fluid {

        margin-bottom: rem(15px);
    }

    a {

        color: $secondary;

		&:hover {

			color: $primary;

		}
    }

    div.aktuelles {

        background: url(../images/info.png) 15px 18px no-repeat $grey;
        padding: rem(15px) rem(10px) rem(15px) rem(50px);
        margin-bottom: 28px;

        @include breakpoint(tiny) {

                background: url(../images/info.png) 15px 15px no-repeat $grey;
        }

        @include breakpoint(small) {

            max-width: rem(430px);
        }

        p {

            font-family: $display-font;
            font-size: rem(14px);
            text-transform: uppercase;
            margin-bottom: 0;

            @include breakpoint(tiny) {

                font-size: rem(16px);
            }

        }
    }

    div.mandentenzugang {

        background: $grey;
        padding: rem(15px);
        margin-bottom: rem(15px);

        @include breakpoint(large) {

            padding: rem(43px) rem(25px);

        }

        p.headline {

            font-family: $display-font;
            font-size: rem(16px);
            text-transform: uppercase;

        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    embed {
        margin-bottom: rem(15px);
        width: 100%;
    }

    ul {
        &.noArrows {
            li {
                padding-left: 0.3rem;
                &:before { display: none; }
            }
        }
        li {
            position: relative;
            padding-left: rem($base-gap * 1.1);
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                transform: translateY(25%);
            }
        }
    }
}


footer {


    padding-top: 0;
     padding-bottom: rem(20px);
     border-bottom: rem(5px) solid $secondary;

    @include breakpoint(large) {

        padding-top: rem(40px);

    }

    @include breakpoint(giant) {

        width: #{rem($row-max-width - ($base-gap * 2))};
        margin: 0 auto;
        padding-top: 0;

    }

    p.headline {

            font-family: $display-font;
            font-size: rem(15px);
            text-transform: uppercase;

            @include breakpoint(small) {

                font-size: rem(21px);
            }

            @include breakpoint(medium) {

                 font-size: rem(15px);
            }

            @include breakpoint(large-more) {

                 font-size: rem(17px);
            }

            @include breakpoint(giant) {

                 font-size: rem(19px);
            }

    }



    a.android {

        display: block;
        width: rem(144px);
        height: rem(50px);
        background: url(../images/googleplay.png) no-repeat;
        float: left;
        margin-bottom: rem(30px);

        @include breakpoint(tiny) {

            float: right;
            margin-left: rem(15px);
        }

        @include breakpoint(small) {

                 margin-bottom: rem(50px);
            }
        @include breakpoint(giant) {
            body.firefox & {
                margin-top: rem(15px);
            }
        }

    }

    a.apple {

        display: block;
        width: rem(144px);
        height: rem(50px);
        background: url(../images/appstore.png) no-repeat;
        float: left;
         margin-bottom: rem(30px);

         @include breakpoint(tiny) {

            float: right;

        }

        @include breakpoint(small) {

                 margin-bottom: rem(50px);
            }
        @include breakpoint(giant) {
            body.firefox & {
                margin-top: rem(15px);
            }
        }

    }

    .navi-add {

        display: none;

        @include breakpoint(large) {

            display: block;
            float: right;
            margin-top: rem(30px);

            li {

                float: left;
                display: inline;

                a {

                    font-size: rem(14px);
                    font-family: $display-font;
                    text-transform: uppercase;
                    border-right: 1px solid $secondary;
                    line-height: 0;
                    margin-top: rem(20px);
                    margin-bottom: rem(60px);
                    padding-right: rem(13px);
                    padding-left: rem(13px);

                    &.impressum {

                        border: none;
                        padding-right: 0;
                    }

                    &.active { color: $secondary;}

                }
            }

        }

        @include breakpoint(giant) {

            margin-top: rem(60px);
           li a {

                font-size: rem(16px);
                 padding-right: rem(17px);
                    padding-left: rem(17px);
            }
        }

    }

    .logo {

         @include breakpoint(tiny) {

                max-width: rem(364px);
                margin: 0 auto;
         }
    }
}

.mobile-hidden {

    display: none;

    @include breakpoint(large-more) {

        display: block;
    }
}
// Misc

.highlight {
    color: $secondary;
}

.btn {
    background: $light;
    border: 1px solid $secondary;;
    color: $secondary;
    font-family: $display-font;
    text-transform: uppercase;
    width: 100%;

    cursor: pointer;
    display: block;
    font-size: 1rem;
    line-height: 1rem;
    padding: .7rem 1rem;
    text-align: center;
    text-decoration: none;
    transition: 300ms all ease-in-out;

    @include breakpoint(large-more) {

        border: none;
        text-align: left;
        color: $primary!important;
        padding: 0;
        margin-bottom: 10px;
        font-size: 16px!important;
        background: none;

    }

    &:hover, &:active, &:focus {
        background: $secondary;
        color: $primary;

        @include breakpoint(large-more) {

             background: none;
            color: $secondary;


        }
    }

    *[class^="icon"] {
        margin-left: .5rem;
        vertical-align: middle;
    }
}

.googleMaps {
    border: none;
    height: em(350px);
    margin-bottom: 1.5rem;
    width: 100%;
}

.notification {
    padding: 1em;
    background: $alert;
    color: $light;
    font-size: 1.2em;
}

.fancy {
    position: relative;
    text-decoration: none;

    &:before {
        content: "";
        position: absolute;
        width: 0;
        top: 100%;
        left: 0;
        height: 1px;
        background: $primary;
        transition: 300ms all ease-in-out;
    }

    &:hover:before {
        width: 100%;
    }
}
