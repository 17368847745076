// +++++ Module imports

// Import modules
@import 'modules/columns';
@import 'modules/utilities';
@import 'modules/typography';
@import 'modules/responsive';
// @import 'modules/custom';

// *** Uncomment this @import if you want to use sprites.
// *** Be sure to copy your sprites into images/sprites and run "grunt sprite" once.
// @import "modules/sprites";

// Relativ path to images folder (used in the image-url() function)
$image-url-path: "../images/" !default;

// +++++ Font settings
// Font size of main content (in px)
$base-font-size: 14px;

// Line height of main content (in px)
$base-line-height: 22px;

// Headline font sizes (in px)
$h1-size: 32px;
$h2-size: 28px;
$h3-size: 24px;
$h4-size: 22px;
$h5-size: 18px;
$h6-size: 16px;

// Font stacks: $main-font for simple content, $display-font for headlines etc., $icon-font for icons
$main-font:    "Droid Serif", serif;
$display-font: "Droid Sans", sans-serif;
$icon-font:    "userinterface";


// +++++ Design & Layout
// Colours for your design.
$primary:   #504f4f;
$secondary: #ff7e00;
$light:     #fff;
$medium:    #c7c6c6;
$dark:      #2a2a2a;
$border:    #bbb;
$alert:     #D85D00;
$grey: 		#f6f6f6;

// Horizontal padding left and right of grid columns
$base-gap: 18px;

// Grid row max-width
$row-max-width: 1200px;

// Standard border for your design
$base-border: 1px solid $border;

// Assume a different base font size for breakpoint em calculation (default: 16px)
$bp-context: 16px;

// Breakpoints for min-width media queries (used in _responsive.scss and _grid.scss)
$breakpoints: (
    'tiny':   em(481px, $bp-context),
    'small':  em(640px, $bp-context),
    'medium': em(752px,  $bp-context),
    'large':  em(992px, $bp-context),
    'large-more':  em(1039px, $bp-context),
    'giant':  em(1200px, $bp-context),
);

 // +++++ Miscellaneous
// text-indent value when using hide-text(): "right" = positive value, "left" = negative value
$hide-text-direction: "right";

// +++++ The following settings can be left alone in most cases
// Column selector names
$column-names: "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12";
