/**
 * Colorbox
 */
#cboxOverlay {
    background: rgba($dark, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

#cboxWrapper {
    background: $light;
    box-shadow: 0 0 rem(2px) rem(1px) rgba(0, 0, 0, .2);
}

#cboxPrevious {
    top: 50%;
    left: -3rem;
    @extend %cbox-button;

    &:before {
        @extend %cbox-button-before;
        @extend %icon-left;
    }
}

#cboxNext {
    top: 50%;
    right: -3rem;
    @extend %cbox-button;

    &:before {
        @extend %cbox-button-before;
        @extend %icon-right;
    }
}

#cboxClose {
    color:$light;
    top: 0;
    right: rem(-25px);
    @extend %cbox-button;

    &:hover {
        background: $secondary;
    }
    &:before {
        @extend %cbox-button-before;
        @extend %icon-cancel;
    }
}


#cboxCurrent {
    bottom: -1.5em;
    left: 1rem;
    color: $dark;
    font-family: $main-font;
    font-size: rem(12px);
    line-height: 1.4em;
    position: absolute;
}

#cboxTitle {
    color: $dark;
    font-family: $main-font;
    font-size: rem(16px);
    background: white;
    line-height: 1.4em;
    min-height: 2rem;
    position: absolute;
    bottom: -2rem;
    left: 0;
    text-align: center;
    width: 100%;
}

#cboxSlideshow {
    color: $medium;
    font-size: rem(12px);
    font-weight: bold;
    font-family: $main-font;
    position: absolute;
    bottom: rem(4px);
    right: rem(30px);
    border: 0;
    overflow: visible;
    width: auto;
    background: none;
    @extend %cbox-transition;
    margin: 0;
    padding: 0;

    &:hover {
        color: $medium;
        font-size: rem(12px);
        font-weight: bold;
        font-family: $main-font;
    }
}

#colorbox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1100;
    @extend %cbox-wrappers;
}

#cboxMiddleLeft, #cboxBottomLeft {
    clear: left;
}

#cboxContent {
    position: relative;
    background: $light;
    @extend %cbox-wrappers;
    padding:rem(10px);
}

#cboxLoadedContent {
    background: $light;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    @extend %cbox-wrappers;
}

#cboxLoadingOverlay, #cboxLoadingGraphic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow {
    cursor: pointer;
}

.cboxPhoto {
    float: left;
    border: 0;
    display: block;
    max-width: none;
    -ms-interpolation-mode: bicubic;
    margin: auto;
}

.cboxIframe {
    width: 100%;
    height: 100%;
    display: block;
    border: 0;
    background: $light;
}

#cboxError {
    border: rem(1px) solid $medium;
    padding: rem(50px);
}

#cboxLoadingGraphic {
    background: image-url("loading.gif") no-repeat center center;
}


.cboxIE {
    #cboxTopLeft, #cboxTopCenter, #cboxTopRight, #cboxBottomLeft, #cboxBottomCenter, #cboxBottomRight, #cboxMiddleLeft, #cboxMiddleRight {
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#00FFFFFF,endColorstr=#00FFFFFF);
    }
}

#colorbox, #cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {
    outline: 0;
}
