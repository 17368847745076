* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::selection, ::-moz-selection {
    background: $medium;
}

// DO NOT set font-size and line-height here!
// Adjust $base-font-size and $base-line-height in _config.scss
html {
  
    color: $primary;
    font-family: $main-font;
    font-size: 100% * ($base-font-size / 16px);
    line-height: rem($base-line-height);

    /* disable text resize in landscape. e.g. on iphone */
    text-size-adjust: none;
}

body {
    // Debug mode
    &.the-customer-page #log_hider {
        display: none;
    }

    &.ie-8 #pageloader:after {
        display: none;
    }
}

/**
 * Headlines
 */
h1 {
    @extend %optimized-rendering;
    font-family: $display-font;
    font-size: rem(16px);
    line-height: 1.6em;
    text-transform: uppercase;
    margin-bottom: rem(15px);
    font-weight: normal;
    padding-bottom: rem(10px);
    border-bottom: 1px solid $medium;

     @include breakpoint(small) {
        
        font-size: rem(18px);
        margin-bottom: rem(25px);

    }

     @include breakpoint(large) {
        
         font-size: rem(21px);
        margin-bottom: rem(60px);
    }

    span {

        color: $secondary;
        font-size: rem(20px);
        display: block;

        @include breakpoint(small) {
        
        font-size: rem(24px);

        }

         @include breakpoint(large) {
        
         font-size: rem(30px);
       
    }
    }
}

h2, h3 {
    @extend %optimized-rendering;
    font-size: rem($h2-size);
    line-height: 1.3em;
    text-transform: uppercase;
	font-family: $display-font;
	font-size: rem(18px);
	font-weight: normal;
	margin-bottom: rem(15px);
}



h4 {
    @extend %optimized-rendering;
    font-size: rem($h4-size);
}

h5 {
    @extend %optimized-rendering;
    font-size: rem($h5-size);
}

h6 {
    @extend %optimized-rendering;
    font-size: rem($h6-size);
}

/**
 * Links
 */
a {
    color: $primary;
    text-decoration: none;

    &:focus, &:hover, &:active {
        color: $secondary;
    }

    img {
        border: none;
    }

    &[href*="bewertet.de"] {
        display: block;
        margin-bottom: rem($base-line-height / 2);
    }

    &[href$=".pdf"]:before {
        @extend .icon;
        @extend %icon-file-pdf;
        margin-right: 0.5em;
    }
}


.embed-container {
        position: relative; 
        padding-bottom: 56.25%; 
        height: 0; 
        overflow: hidden; 
        max-width: 100%; 
		margin-bottom: rem(45px);

        iframe, 
        object, 
        embed,
        video { 
                    position: absolute; 
                    top: 0; 
                    left: 0; 
                    width: 100%; 
                    height: 100%; 
					border: none;
					
                }
		
} 



/**
 * Images
 */
img {
    vertical-align: bottom;
}

/**
 * Paragraphs
 */
p {
    margin-bottom: 1.2em;
    hyphens: auto;
}

hr {
    height: rem(1px);
    border: none;
    background: $medium;
    clear: both;
    margin: 1rem auto;
    max-width: #{rem($row-max-width - ($base-gap * 2))};
}

/**
 * Lists
 */
ul, ol, dl {
    margin-bottom: 2rem;
    hyphens: auto;
}

ul {
    li {
        padding: .3rem 0;

        &:before {
            content: "»";
            line-height: 1.5rem;
            margin-right: .5rem;
        }
    }
    ul {
        margin-left: 1rem;
        margin-bottom: 0;
    }
    list-style: none;
}

/**
 * Tables
 */
table {
    border-collapse: collapse;
    margin-bottom: 1rem;
    hyphens: auto;
    width: 100%;
}

caption {
    font-size: 1.2rem;
    font-weight: 700;
    padding-bottom: .5rem;
}

// Responsive table styles
table, thead, tbody, th, td, tr, caption {
    display: block;
}

thead {
    border-collapse: collapse;

    tr {
        position: absolute;
        top: -999%;
        left: -999%;
    }
}

tbody tr {
    border-bottom: 1px solid $medium;
    padding: rem($base-gap) 0;
}

td {
    min-height: 1rem;
    padding: .3rem rem($base-gap) .3rem 35%;
    position: relative;

    &:before {
        content: attr(data-label) ": ";
        font-weight: bold;
        left: 1rem;
        position: absolute;
        top: .3rem;
        white-space: nowrap;
        width: 45%;
    }
}

// Restore proper table display values for larger screens
@include breakpoint(small) {
    table {
        display: table;
    }

    caption {
        display: table-caption;
    }

    thead {
        display: table-header-group;
    }

    tbody {
        display: table-row-group;
    }

    tr {
        display: table-row;
    }

    th, td {
        display: table-cell;
        vertical-align: top;
    }

    td {
        min-height: inherit;
        padding: rem($base-gap) 1rem;

        &:before {
            display: none;
            content: "";
            width: auto;
        }
    }

    thead tr {
        position: static;
        left: auto;
        top: auto;
    }
}
