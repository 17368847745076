/**
 * Grid system
 */
.row {
    @extend .container;

    .row {
        margin-left:-#{rem($base-gap)};
        margin-right:-#{rem($base-gap)};
        padding-left:0;
        padding-right:0;
    }
}

.container {
    margin-left:auto;
    margin-right:auto;
    max-width: rem($row-max-width);
    position: relative;
    @include pie-clearfix;
}

// Initialize grid
.col {
    float: left;
    padding-left: rem($base-gap);
    padding-right: rem($base-gap);
    width:100%;
    @include pie-clearfix;
}

// Grid classes for default breakpoint (no min-width)
@include grid-columns(default);

// Generate column classes
@each $point, $width in $breakpoints {
    @include breakpoint($point) {
        @include grid-columns($point);
    }
}
